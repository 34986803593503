/* @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Special+Elite&display=swap'); */
@font-face {
  font-family: "SpecialElite-Regular";
  src: url("./packages/font_family/SpecialElite-Regular/fonts/SpecialElite-Regular.eot"); /* IE9 Compat Modes */
  src: url("./packages/font_family/SpecialElite-Regular/fonts/SpecialElite-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./packages/font_family/SpecialElite-Regular/fonts/SpecialElite-Regular.otf") format("opentype"), /* Open Type Font */
    url("./packages/font_family/SpecialElite-Regular/fonts/SpecialElite-Regular.svg") format("svg"), /* Legacy iOS */
    url("./packages/font_family/SpecialElite-Regular/fonts/SpecialElite-Regular.woff") format("woff"), /* Modern Browsers */
    url("./packages/font_family/SpecialElite-Regular/fonts/SpecialElite-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Quantico&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: #16262E;
  /* background: url('./wallpaper/black-background.png') black;
  background-size: cover; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container{
  height: 100vh;
  position: relative;
  
}
